<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import moment from "moment";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      currenTotal: 0,
      day: moment(new Date(Date.now())).format("YYYY-MM-DD"),
      payid: [],
      final_total: [],
      billing_invoices: [],
      customerData: [],
      page: 1,
      total: 0,
      status: 1,
      limit: 20,
      pay_amount: 0,
      total_amount: 0,
      tot_pages: 0,
      searchBillingInvoices: null,
      BillingInvoicesSearchMode: false,
      resultStatus: null,
      searchModel: "",
      billing_invoices_id: [],
      x: [],
      customerInfo: [],
      search: "",
      selectedCustomers: null,
      bill: [],
      allSelected: false,
      searchInput: null,
      customer_id: null,
      branch_id: null,
      customer_bill_id: null,

      // appprices:[]
    };
  },
  computed: {
    filteredCountries() {
      return this.customerInfo
        .filter((customerInfo) => {
          console.log(this.customerInfo);
          if (customerInfo.name.includes(this.search)) {
            console.log("mohamed test");
            console.log(customerInfo.name);
            return customerInfo.name;
          } else if (customerInfo.account_no == this.search) {
            return customerInfo.name;
          } else if (customerInfo.meter_no == this.search) {
            return customerInfo.name;
          }
        })
        .map((customerInfo) => {
          return (
            customerInfo.id +
            "-" +
            "(" +
            customerInfo.meter_no +
            ")" +
            "-" +
            customerInfo.name
          );
        });
    },
    generate() {
      return this.final_total
        .map((c) => {
          console.log("total", c.customer.tariff.fees);
          return c.customer.tariff.fees;
        })
        .reduce((a, b) => {
          this.total = parseInt(a) + parseInt(b);
          return this.total;
        }, 0);
    },
  },
  methods: {
    get(page) {
      this.day = moment(new Date(Date.now())).format("YYYY-MM-DD");
      console.log(page);
      this.http
        .post("billing-invoices/pagination", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.billing_invoices = res.data;
        });
    },
    // generate() {
    //
    //   this.final_total.map(c => {
    //     console.log("total", c.customer.tariff.fees)
    //     return c.customer.tariff.fees
    //   }).reduce((a, b) => {
    //     this.total = parseInt(a) + parseInt(b)
    //     return this.total

    //   }, 0);
    //   console.log("_)_)_", this.total)
    // }
  },

  created() {
    const current_user = JSON.parse(localStorage.currentUser);
    this.branch_id = current_user.branch_id;
     this.get(1)
    // this.selectedCustomer()
    //  this.getBillingInvoices()
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.billing.subItems.billing_invoices')"
    />



    <!-- end customer table -->

    <div>
      <table
        :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
        class="table table-centered table-nowrap table-striped table-hover align-middle"
      >
        <thead>
          <tr class="text-light" style="background-color: #2a3042 !important">
            <th scope="col">{{ $t("customers.id") }}</th>

            <th scope="col">{{ $t("customers.name") }}</th>
            <th scope="col">{{ $t("customers.neighborhood") }}</th>
            <th scope="col">{{ $t("customers.tariff") }}</th>
            <th scope="col">{{ $t("bills_receivable.remain") }}</th>
            <th scope="col">{{ $t("report.month") }}</th>
            <th scope="col">{{ $t("customers.created") }}</th>
            <th scope="col">{{ $t("customers.status") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(app, index) in billing_invoices" :key="app" class="">
            <td>{{ (page-1)*limit + index + 1 }}</td>
            <td>{{ app?.customer?.name }}</td>
            <td>{{ $i18n.locale=='ar' ? app?.customer?.neighborhood?.name : app?.customer?.neighborhood?.name_en}}</td>
            <td>{{ parseFloat(app?.total_balance)?.toLocaleString() }}</td>
            <td>{{ parseFloat( app?.total_balance - app?.paid_balance )?.toLocaleString() }}</td>
            <td>{{ moment(app?.created).format("MMMM Y") }}</td>
            <td>{{ app?.updated?.split("T")[0] }}</td>
            <td>
              <h6
                v-if="app?.paid_balance != app?.total_balance"
                class="text-danger"
              >
                {{ $t("popups.unPaid") }}
              </h6>
              <span
                v-if="app?.paid_balance == app?.total_balance"
                class="text-success"
                >{{ $t("popups.paid") }}</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- end  table -->


    <!--end-->
    <!--   Apps  Pagination     -->
    <div>
      <ul class="pagination pagination-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page - 1)"
            aria-label="Previous"
          >
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li
          class="page-item"
          :class="{ active: p == page }"
          v-for="p in tot_pages"
          :key="p"
        >
          <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
            p
          }}</a>
        </li>

        <li class="page-item" :class="{ disabled: page == tot_pages }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page + 1)"
            aria-label="Next"
          >
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
    <!--end-->

    <div class="page-content container">
      <!-- <div class="page-header text-blue-d2">
                            <h1 class="page-title text-secondary-d1">
                                Invoice
                                <small class="page-info">
                                    <i class="fa fa-angle-double-right text-80"></i>
                                    ID: #111-222
                                </small>
                            </h1>

                            <div class="page-tools">
                                <div class="action-buttons">
                                    <a class="btn bg-white btn-light mx-1px text-95" href="#" data-title="Print">
                                        <i class="mr-1 fa fa-print text-primary-m1 text-120 w-2"></i>
                                        Print
                                    </a>
                                    <a class="btn bg-white btn-light mx-1px text-95" href="#" data-title="PDF">
                                        <i class="mr-1 fa fa-file-pdf-o text-danger-m1 text-120 w-2"></i>
                                        Export
                                    </a>
                                </div>
                            </div>
                        </div> -->
    </div>
  </Layout>
</template>

<style scoped>
body {
  margin-top: 20px;
  color: #484b51;
}

.text-secondary-d1 {
  color: #728299 !important;
}

.page-header {
  margin: 0 0 1rem;
  padding-bottom: 1rem;
  padding-top: 0.5rem;
  border-bottom: 1px dotted #e2e2e2;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
}

.page-title {
  padding: 0;
  margin: 0;
  font-size: 1.75rem;
  font-weight: 300;
}

.brc-default-l1 {
  border-color: #dce9f0 !important;
}

#bill {
  width: 100%;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.text-grey-m2 {
  color: #888a8d !important;
}

.text-success-m2 {
  color: #86bd68 !important;
}

.font-bolder,
.text-600 {
  font-weight: 600 !important;
}

.text-110 {
  font-size: 110% !important;
}

.text-blue {
  color: #478fcc !important;
}

.pb-25,
.py-25 {
  padding-bottom: 0.75rem !important;
}

.pt-25,
.py-25 {
  padding-top: 0.75rem !important;
}

.bgc-default-tp1 {
  background-color: rgba(121, 169, 197, 0.92) !important;
}

.bgc-default-l4,
.bgc-h-default-l4:hover {
  background-color: #f3f8fa !important;
}

.page-header .page-tools {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.btn-light {
  color: #757984;
  background-color: #f5f6f9;
  border-color: #dddfe4;
}

.w-2 {
  width: 1rem;
}

.text-120 {
  font-size: 120% !important;
}

.text-primary-m1 {
  color: #4087d4 !important;
}

.text-danger-m1 {
  color: #dd4949 !important;
}

.text-blue-m2 {
  color: #68a3d5 !important;
}

.text-150 {
  font-size: 150% !important;
}

.text-60 {
  font-size: 60% !important;
}

.text-grey-m1 {
  color: #7b7d81 !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.modal-content {
  width: 800px;
}

.c {
  position: relative;
  right: 900px;
  top: 5px;
}

.tot {
  display: flex;
  justify-content: flex-end;
}

.tot2 {
  display: flex;
  justify-content: flex-end;
  right: 10px;
}
</style>
